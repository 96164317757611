import React from "react"
import {
  Flex,
  Text,
  Heading,
  Box,
  Link,
  OrderedList,
  ListItem,
} from "@chakra-ui/react"

import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { ALL_STAR_PAGES_URL } from "../../../constants/constants"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
  },
  "inner-box": {
    margin: "0 auto",
    my: { base: "20px", md: "20px", lg: "60px" },
    width: "100%",
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: { base: "flex-start", md: "flex-start", lg: "center" },
  },
  "left-box": {
    maxWidth: { base: "100%", md: "100%", lg: "576px" },
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    mr: { base: "0px", md: "0px", lg: "40px" },
  },
  "right-box": {
    mr: { base: "0", md: "0", lg: "89px" },
    maxWidth: { base: "100%", md: "100%", lg: "375px" },
  },
  "heading-box": {
    width: "100%",
    mt: { base: "40px", md: "40px", lg: "0px" },
  },
  heading: {
    fontSize: { base: "32px", md: "32px", lg: "40px" },
    fontWeight: 700,
    lineHeight: { base: "40px", md: "40px", lg: "48px" },
    textAlign: "center",
    letterSpacing: "0em",
  },
  greeting: {
    fontSize: { base: "24px", md: "24px", lg: "32px" },
    fontWeight: 700,
    lineHeight: { base: "32px", md: "32px", lg: "40px" },
    letterSpacing: "0em",
    color: "#129459",
  },
  "sub-heading": {
    mt: "8px",
    fontSize: { base: "18px", md: "18px", lg: "24px" },
    lineHeight: { base: "21.48px", md: "21.48px", lg: "32px" },
    fontWeight: 700,
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#2D3748",
    alignItems: "center",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 700,
    lineHeight: "24px",
  },
  tag: {
    height: "32px",
    padding: "4px 16px 4px 16px",
    bg: "#ECC94B",
  },
  "tag-text": {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  "allstar-text": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#129459",
  },
  hero: {
    mt: { base: "16px", md: "16px", lg: "40px" },
    width: { base: "144px", md: "144px", lg: "240px" },
  },
  "page-links": {
    alignItems: "center",
    flexWrap: "wrap",
  },
  seperator: {
    marginRight: "16px",
    mb: "16px",
  },
  divider: {
    marginRight: "16px",
    mb: "16px",
    h: "24px",
    borderColor: "#CCCCCD",
  },
}

const SectionHero = () => {
  return (
    <Box sx={styles.root}>
      <Flex sx={styles["inner-box"]}>
        <Flex sx={styles["left-box"]}>
          <Box sx={styles["heading-box"]}>
            <Heading sx={styles["sub-heading"]}>
              Meet the best surgeons and hospitals for healthcare research
            </Heading>
          </Box>

          <Text sx={styles.text} mt="24px">
            Welcome to the elite list of Healthcare Research All-Stars for 2024!
          </Text>
          <Text sx={styles.text} mt="24px">
            The surgeons and hospitals named to the All-Star lists are honored
            in recognition of the quality and quantity of their published
            research. To be included as an All-Star, surgeons and hospitals must
            be among the top 5% of those publishing leading-edge healthcare
            research.
          </Text>
          <Text sx={styles.text} mt="24px">
            The surgeon and hospital All-Star lists are grouped by specialty and
            also within an overall list of hospitals across all specialties.
            Within each category, the lists are divided into three tiers as
            follows:
          </Text>

          <OrderedList mt="24px">
            <ListItem sx={styles.text}>Top 1% (99th Percentile)</ListItem>
            <ListItem sx={styles.text}>Top 2-3% (97-98th Percentile)</ListItem>
            <ListItem sx={styles.text}>Top 4-5% (95-96th Percentile)</ListItem>
          </OrderedList>

          <Text sx={styles.text} mt="24px">
            For additional details on the lists, please review the study{" "}
            <Link
              color="#3399FF"
              as={GatsbyLink}
              to={"/" + ALL_STAR_PAGES_URL.METHODOLOGY}
            >
              Methodology
            </Link>{" "}
            and see answers to{" "}
            <Link
              color="#3399FF"
              as={GatsbyLink}
              to={"/" + ALL_STAR_PAGES_URL.FAQ}
            >
              Frequently Asked Questions
            </Link>
            .
          </Text>
        </Flex>
        <Box sx={styles["right-box"]}>
          <Flex flexDirection="column" alignItems="center">
            <Heading as="h2" sx={styles["allstar-text"]}>
              Healthcare Research All-Stars
            </Heading>
            <Heading as="h2" sx={styles.heading} mt="8px">
              Best Surgeons and Hospitals
            </Heading>

            <Box sx={styles.hero}>
              <StaticImage
                src="../../../images/allstar/Healthcare Research All-Stars 2024 - Logo.png"
                alt="Healthcare Research All-Stars Logo"
                placeholder="blurred"
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default SectionHero
