import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/allstar/layout"
import SectionBody from "../components/allstar/home/section-body"
import SectionHero from "../components/allstar/home/section-hero"
import SectionSpecialties from "../components/allstar/home/section-specialties"

const HomePage = props => {
  return (
    <Layout
      location={props.location}
      title="Best Surgeons & Hospitals Research All-Stars"
    >
      <SectionHero />

      <SectionBody data={props.data} />
      <SectionSpecialties data={props.data} />
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query homePageQuery {
    allSurgeons: allFile(
      filter: { relativeDirectory: { eq: "allstar/surgeons" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allBadges: allFile(
      filter: { relativeDirectory: { eq: "allstar/badges" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
