import React from "react"
import { Box, Text } from "@chakra-ui/react"
import Carousel from "./carousel"
import VideoInterview from "./video-interview"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
    bg: "#F4F7FF",
    justifyContent: "center",
  },
  content: {
    pt: { base: "24px", md: "24px", lg: "60px" },
    pb: { base: "88px", md: "116px", lg: "116px" },
    bg: "#F4F7FF",
    maxWidth: "1080px",
  },
  "sub-heading": {
    textAlign: "center",
    fontSize: { base: "18px", md: "18px", lg: "24px" },
    lineHeight: { base: "21.48px", md: "21.48px", lg: "32px" },
    fontWeight: 700,
  },
}

const SectionBody = props => {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.content} marginX={"auto"}>
        <Text sx={styles["sub-heading"]}>
          What the Research All-Star Surgeons are Saying
        </Text>
        <Box mt="32px">
          <Carousel data={props.data} />
        </Box>
      </Box>
      <VideoInterview />
    </Box>
  )
}

export default SectionBody
