import React from "react"
import { Box, Flex, Grid, Text, Link, Image } from "@chakra-ui/react"
import { navigate } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"

import { ALL_STAR_MAIL_LINK, SPECIALTIES } from "../../../constants/constants"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
    justifyContent: "center",
  },
  content: {
    py: { base: "24px", md: "24px", lg: "60px" },
    maxWidth: "1080px",
  },
  "inner-box": {
    maxWidth: "1080px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
  },
  "sub-heading": {
    fontSize: { base: "18px", md: "18px", lg: "24px" },
    lineHeight: { base: "21.48px", md: "21.48px", lg: "32px" },
    fontWeight: 700,
  },
  title: {
    fontSize: { base: "18px", md: "18px", lg: "24px" },
    fontWeight: 700,
    lineHeight: { base: "21.48px", md: "21.48px", lg: "32px" },
  },
  note: {
    fontSize: "16px",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#6E7E99",
  },
  text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2D3748",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 400,
    lineHeight: "24px",
  },
  "specialty-item": {
    flexDirection: "column",
    alignItems: "center",
    py: "12px",
    px: "20px",
    cursor: "pointer",
    borderRadius: "4px",
    border: "1px solid #D9DCE6",
    justifyContent: "center",
    height: { base: "auto", md: "auto", lg: "150px" },
    "&:hover,&:active,&:focus": {
      boxShadow: "0px 8px 12px 0px #2D37481A",
    },
  },
  "specialty-text": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    color: "#3399FF",
    textAlign: "center",
    maxW: { base: "auto", md: "auto", lg: "160px" },
  },
}

const openSpecialtyPage = link => navigate(link)

const SectionSpecialties = props => {
  const filteredBadges = SPECIALTIES.map(badge => {
    const item = props.data.allBadges.edges.find(
      item => item.node.name === badge.image
    )
    if (item) {
      return {
        ...item,
        specialty: badge.name,
        pageSlug: badge?.pageSlug,
      }
    }
    return null
  }).filter(Boolean)

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content} marginX="auto">
        <Text sx={styles["sub-heading"]}>
          2024 Healthcare Research All-Stars: Hospitals
        </Text>

        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(5, 1fr)",
          }}
          gap="20px"
          mt="20px"
        >
          <Flex
            sx={styles["specialty-item"]}
            key={filteredBadges?.[0]?.specialty}
            onClick={() => {
              openSpecialtyPage(filteredBadges?.[0]?.pageSlug)
            }}
          >
            <Image
              as={GatsbyImage}
              alt={filteredBadges?.[0]?.specialty}
              image={filteredBadges?.[0]?.node.childImageSharp?.gatsbyImageData}
              w="52px"
            />
            <Link mt="12px" sx={styles["specialty-text"]}>
              Overall Hospital All&#x2011;Stars
            </Link>
          </Flex>
        </Grid>

        <Text sx={styles["sub-heading"]} mt="44px">
          2024 Healthcare Research All-Stars: Specialties
        </Text>
        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(5, 1fr)",
          }}
          gap="20px"
          mt="20px"
        >
          {filteredBadges.map((badge, index) => {
            if (index === 0) {
              return null
            }
            return (
              <Flex
                sx={styles["specialty-item"]}
                key={badge.specialty}
                onClick={() => {
                  openSpecialtyPage(badge?.pageSlug)
                }}
              >
                <Image
                  as={GatsbyImage}
                  alt={badge?.specialty}
                  image={badge.node.childImageSharp?.gatsbyImageData}
                  w="52px"
                />
                <Link mt="12px" sx={styles["specialty-text"]}>
                  {badge.specialty}
                </Link>
              </Flex>
            )
          })}
        </Grid>

        <Text sx={styles.text} mt="44px">
          Questions about the Physician Research All-Stars? Please contact us at{" "}
          <Link sx={styles.link} href={ALL_STAR_MAIL_LINK}>
            Research.All-Stars@avantgardehealth.com
          </Link>
          .
        </Text>
      </Box>
    </Box>
  )
}

export default SectionSpecialties
