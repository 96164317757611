import React from "react"
import { Box } from "@chakra-ui/react"
import Video from "../../video"

const styles = {
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    px: { base: "0px", md: "0px", lg: "20%" },
    pb: { base: "48px", md: "48px", lg: "48px" },
  },
  video: {
    width: "100%",
  },
}

const VideoInterview = () => {
  return (
    <Box sx={styles.box}>
      <Video
        style={styles.video}
        video={
          "https://player.vimeo.com/video/927555837?autoplay=1&amp;h=bee90e3cec"
        }
      />
    </Box>
  )
}

export default VideoInterview
