import React, { useMemo } from "react"
import Slider from "react-slick"
import { Box, Text, Flex, Icon, Image } from "@chakra-ui/react"

import { RiDoubleQuotesL } from "react-icons/ri"

import { GatsbyImage } from "gatsby-plugin-image"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./styles.css"

import {
  ALL_STAR_SURGEON_QUOTES,
  SLIDER_INTERVAL,
} from "../../../constants/constants"

const styles = {
  root: {
    maxWidth: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  "inner-box": {
    px: { base: "12px", md: "36px", lg: "80px" },
    py: "60px",
    bg: "#FFFFFF",
    borderRadius: "8px",
    flexDirection: "column",
    alignItems: "center",
  },
  "active-dot": {
    h: "20px",
    w: "20px",
    borderRadius: "50%",
    bg: "#129459",
    mt: "-2px",
    ml: "-2px",
  },
  "inactive-dot": {
    h: "16px",
    w: "16px",
    borderRadius: "50%",
    border: "2px solid #129459",
  },
  text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2D3748",
    textAlign: { base: "center", md: "left", lg: "left" },
  },
  "surgeon-detail": {
    flexDir: { base: "column", md: "row", lg: "row" },
    alignItems: { base: "center", md: "flex-start", lg: "flex-start" },
  },
  "surgeon-title": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: { base: "center", md: "left", lg: "left" },
  },
  image: {
    minWidth: "88px",
    mr: "28px",
  },
}

const Carousel = props => {
  const [activeIndex, setActiveIndex] = React.useState(0)

  const surgeonSlides = useMemo(() => {
    return ALL_STAR_SURGEON_QUOTES.map(slide => {
      const item = props.data.allSurgeons.edges.find(
        item => item.node.name === slide.name
      )
      if (item) {
        return {
          ...slide,
          ...item,
        }
      }
      return null
    }).filter(Boolean)
  }, [props])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: SLIDER_INTERVAL,
    beforeChange: (current, next) => setActiveIndex(next),
    appendDots: dots => (
      <Flex alignItems="center" justifyContent="center" mt="26px">
        {dots}
      </Flex>
    ),

    customPaging: i => (
      <Box
        sx={i === activeIndex ? styles["active-dot"] : styles["inactive-dot"]}
      />
    ),
  }

  return (
    <Box sx={styles["inner-box"]}>
      <Flex justifyContent={"center"}>
        <Icon
          as={RiDoubleQuotesL}
          w="48px"
          h="48px"
          color="#129459"
          marginX="auto"
        />
      </Flex>
      <Slider {...settings}>
        {surgeonSlides.map((slide, index) => (
          <Box key={index}>
            <Text sx={styles.text} fontStyle="italic" mt="28px">
              {slide.quote}
            </Text>
            <Flex sx={styles["surgeon-detail"]} mt="28px">
              <Box sx={styles.image}>
                <Image
                  as={GatsbyImage}
                  alt={slide.name}
                  image={slide.node?.childImageSharp?.gatsbyImageData}
                  borderRadius="full"
                  boxSize="88px"
                />
              </Box>
              <Box mt={{ base: "12px", md: "0", lg: "0" }}>
                <Text sx={styles["surgeon-title"]}>{slide.title}</Text>
                <Text sx={styles.text} mt="8px">
                  {slide.description}
                </Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </Slider>
    </Box>
  )
}

export default Carousel
